<template>
  <st-title
    :navItems="[
      {
        name: $t('studio.business_bank_setting.title'),
        url: 'business-bank'
      },
      {
        name: $t('studio.business_bank_setting.title1')
      }
    ]"
    :title="$t('studio.business_bank_setting.title1')"
  />
  <business-register
    v-if="!isLoading"
    :disabled="isDisabled"
    :isEdit="isEdit"
    @onSubmit="onSubmit"
  />
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useFieldValue, useForm, useSetFieldError } from 'vee-validate';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { definePageMeta } from '@/.nuxt/imports';
import {
  createUpdateBusinessInfoApi,
  fetchBusinessHistoriesApi
} from '@/apis/business-bank-info.api';
import { checkGroupHasRatingObjection } from '@/apis/rating.api';
import BusinessRegister from '@/components/business-bank-info/business-info/register.vue';
import StTitle from '@/components/common/st-title.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showConfirm } from '@/composables/useDialog';
import { BUSINESS_BANK_STATUS, BUSINESS_TYPE, GAME_TYPE } from '@/constants/business-bank.const';
import { YEAR_FORMAT } from '@/constants/date-time.const';
import { COMMON_ERROR_MESSAGE_KEY, STATUS_CODE } from '@/constants/error.const';
import { BUSINESS_BANK_PAGE_URL } from '@/constants/url.const';
import { Confirmation, Gender, Sex } from '@/enums/common.enum';
import { AdditionalRatingType } from '@/enums/rating.enum';
import { RuleNames } from '@/enums/validation.enum';
import { createBusinessInfoMapper } from '@/mappers/business.mapper';
import { useAppStore } from '@/stores/app.store';
import { useBusinessBankStore } from '@/stores/business-bank.store';
import { useUserStore } from '@/stores/user.store';
import type {
  BusinessInfoModel,
  BusinessListContentModel
} from '@/types/business-info/business-model.type';
import type { ErrorResponse } from '@/types/common/common.type';
import { redirectTo } from '@/utils/common.util';
import { formatDateTime } from '@/utils/date.util';
import { generateErrorMsg } from '@/utils/validation.util';

definePageMeta({
  middleware: 'check-business-register-middleware'
});

const app = useApp();

const { t } = useI18n();

const userStore = useUserStore();
const { selectedGroupId, userInfo } = storeToRefs(userStore);

const gender =
  userInfo.value?.personVerifyYn === Confirmation.YES
    ? userInfo.value.sex === Sex.MALE
      ? Gender.MALE
      : Gender.FEMALE
    : '';
const inputBirth =
  userInfo.value?.personVerifyYn === Confirmation.YES && userInfo.value.birthDt
    ? formatDateTime(userInfo.value.birthDt, YEAR_FORMAT)
    : '';

const appStore = useAppStore();

const { gdsInfo } = storeToRefs(appStore);

const businessBankStore = useBusinessBankStore();
const { fetchBusinessInfos } = businessBankStore;
const { businessInfos, businessStatus, businessApproval } = storeToRefs(businessBankStore);
const hasRatingUnderObjection = ref(false);
const isLoading = ref(true);
const hasApprovedBusinessBankInfo = ref<boolean>(false);

const isRejectionOnly = computed(
  () => businessStatus.value === BUSINESS_BANK_STATUS.REJECT && !hasApprovedBusinessBankInfo.value
);
const isEdit = computed(() => {
  return businessStatus.value !== '' && !isRejectionOnly.value;
});
const isDisabled = computed(
  () => hasRatingUnderObjection.value || businessStatus.value === BUSINESS_BANK_STATUS.REQUEST
);

const { handleSubmit, setValues } = useForm<BusinessInfoModel>({
  initialValues: {
    individual: {
      license: '',
      nationality: gdsInfo.value?.nation,
      countryTaxEqualYn: true,
      taxCountry: gdsInfo.value?.nation,
      name: '',
      gender,
      inputBirth,
      birth: '',
      email: '',
      mobileCd: gdsInfo.value?.nation,
      mobileNo: '',
      uploadResidentName: '',
      uploadResident: [],
      gameType: GAME_TYPE.DEVELOPER,
      subBizInfo: [],
      staffInfo: {
        name: '',
        mobileCd: gdsInfo.value?.nation,
        mobileNo: '',
        email: ''
      },
      settInfo: {
        name: '',
        mobileCd: gdsInfo.value?.nation,
        mobileNo: '',
        email: ''
      },
      businessInfo: {
        nation: gdsInfo.value?.nation,
        name: '',
        ceoName: '',
        mobileCd: '',
        mobileNo: '',
        zipCode: undefined,
        city: '', // TAIWAN: city - ward
        ward: '',
        district: '', // VN: district - province
        province: '',
        streetName: '',
        buildingName: '',
        cityName: '',
        cityGroup: '',
        address: '',
        detailAddress: '',
        koreaBusinessRegistrationNumber: undefined,
        koreaBusinessRegistrationContract: '',
        koreaBusinessRegistrationContractNo: [],
        gameDevelopPublishRegistrationNumber: '',
        gameDevelopPublishRegistrationContract: '',
        gameDevelopPublishRegistrationContractNo: [],
        telecommunicationRegistrationNumber: '',
        telecommunicationRegistrationContract: '',
        telecommunicationRegistrationContractNo: [],
        registrationNo: undefined,
        businessRegistrationContractNo: [],
        taxId: '',
        type: '',
        businessType: BUSINESS_TYPE.INDIVIDUAL_BUSINESS,
        subBizDescription: ''
      }
    }
  },
  keepValuesOnUnmount: true
});

const haveLicense = useFieldValue<string>('individual.license');
const companyName = useFieldValue<string>('individual.businessInfo.name');
const setCompanyNameError = useSetFieldError('individual.businessInfo.name');
const setUploadResidentNameError = useSetFieldError('individual.uploadResidentName');
const setGameDevelopPublishRegistrationContractError = useSetFieldError(
  'individual.businessInfo.gameDevelopPublishRegistrationContract'
);
const setTelecommunicationRegistrationContractError = useSetFieldError(
  'individual.businessInfo.telecommunicationRegistrationContract'
);
const setKoreaBusinessRegistrationContractError = useSetFieldError(
  'individual.businessInfo.koreaBusinessRegistrationContract'
);

watch(haveLicense, () => {
  setUploadResidentNameError('');
  setGameDevelopPublishRegistrationContractError('');
  setTelecommunicationRegistrationContractError('');
  setKoreaBusinessRegistrationContractError('');
});

const handleCreateBusiness = async (groupId: string, params: BusinessInfoModel) => {
  if (businessApproval.value?.status === BUSINESS_BANK_STATUS.REQUEST) {
    await showAlert({
      content: t('studio.business_bank_setting.individual.btn_save_alert3'),
      severity: 'info',
      confirmLabel: t('studio.common.popup_case_cf_btn'),
      confirmClasses: '!w-full !max-w-full'
    });

    return;
  }

  const result = await showConfirm({
    content: t('studio.business_bank_setting.individual.btn_save_alert1'),
    confirmLabel: t('studio.common.popup_case_cf_btn'),
    cancelLabel: t('studio.common.popup_case_cancel_btn'),
    cancelVariant: 'outline',
    isCloseButtonShown: true
  });

  if (result) {
    try {
      const requestParams = createBusinessInfoMapper(params);
      const res = await createUpdateBusinessInfoApi(groupId, requestParams, isEdit.value);

      if (res) {
        await showAlert({
          content: t('studio.business_bank_setting.individual.btn_save_alert2'),
          severity: 'info',
          confirmLabel: t('studio.common.popup_case_cf_btn'),
          confirmClasses: '!w-full !max-w-full',
          isCloseButtonShown: true
        });
        await fetchBusinessInfos();
        redirectTo(BUSINESS_BANK_PAGE_URL);
      }
    } catch (err) {
      const error = err as ErrorResponse;
      const errorCode = error.statusCode ?? 0;
      if (errorCode === STATUS_CODE.COMPANY_ALREADY_REQUEST) {
        const res = await showAlert({
          content: t('studio.business_bank_setting.individual.btn_save_alert3'),
          severity: 'info',
          confirmLabel: t('studio.common.popup_case_cf_btn'),
          confirmClasses: '!w-full !max-w-full'
        });

        if (res) {
          redirectTo(BUSINESS_BANK_PAGE_URL);
        }

        return;
      }
      if (errorCode === STATUS_CODE.GROUP_MIGRATION_ALREADY_PROGRESS || errorCode === STATUS_CODE.PROJECT_MIGRATION_ALREADY_PROGRESS) {
        return;
      }
      showAlert({
        content: t(COMMON_ERROR_MESSAGE_KEY),
        severity: 'info',
        confirmLabel: t('studio.common.popup_case_cf_btn'),
        confirmClasses: '!w-full !max-w-full'
      });
    }
  }
};

const onSubmit = handleSubmit(async (value: BusinessInfoModel) => {
  if (!(await app.checkMenuWritable())) {
    return;
  }
  if (haveLicense.value === Confirmation.YES && !companyName.value) {
    const errorMessage = generateErrorMsg(
      'studio.business_bank_setting.individual.query_req_msg',
      RuleNames.REQUIRED
    );
    setCompanyNameError(errorMessage);

    return;
  }
  await handleCreateBusiness(selectedGroupId.value, value);
});

const setBusinessRegistration = async () => {
  if (businessInfos.value) {
    setValues({ ...businessInfos.value });
  }
};

const fetchCheckGroupHasRatingObjection = async () => {
  const checkObjectionRes = await checkGroupHasRatingObjection(selectedGroupId.value);
  if (checkObjectionRes && checkObjectionRes.type === AdditionalRatingType.OBJECTION) {
    hasRatingUnderObjection.value = true;
  }
};

const fetchBusinessHistories = async () => {
  const result = await fetchBusinessHistoriesApi(selectedGroupId.value);
  if (result && result.contents.length > 0) {
    hasApprovedBusinessBankInfo.value = result.contents.some(
      (item: BusinessListContentModel) => item.companyStatus === BUSINESS_BANK_STATUS.APPROVE
    );
  }
};

const init = async () => {
  await Promise.all([fetchCheckGroupHasRatingObjection(), fetchBusinessHistories()]);

  if (businessStatus.value !== '' || isDisabled.value) {
    await setBusinessRegistration();
  }

  isLoading.value = false;
};

init();
</script>
