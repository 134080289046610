<template>
  <right-wing>
    <right-wing-item
      v-for="(guide, idx) in showGuides"
      :key="guide.title + idx"
      :rightWingTitle="$t(guide.title)"
      :numberValue="`0${idx + 1}`"
      :class="{
        'mt-24': idx > 0
      }"
    >
      <safe-html :html="$t(guide.content)" />
    </right-wing-item>
    <template v-if="haveLicense">
      <template v-if="nation === DEFAULT_COUNTRY">
        <right-wing-item
          :rightWingTitle="$t('studio.business_bank_setting.guide2_5')"
          numberValue="03"
          class="mt-24"
        >
          <safe-html :html="$t('studio.business_bank_setting.guide2_6')" />
        </right-wing-item>
        <p class="mt-8 text-sm text-on-surface-elevation-4 leading-md font-regular">
          {{ $t('studio.business_bank_setting.guide2_17') }}
        </p>
        <div class="mt-4">
          <img
            src="@/assets/images/business-bank/11-1-7-sample-1.svg"
            alt=""
            class="w-full block"
          />
        </div>

        <right-wing-item
          :rightWingTitle="$t('studio.business_bank_setting.guide2_7')"
          numberValue="04"
          class="mt-24"
        >
          <safe-html :html="$t('studio.business_bank_setting.guide2_8')" />
        </right-wing-item>
      </template>
      <template v-else>
        <right-wing-item
          :rightWingTitle="$t('studio.business_bank_setting.guide2_9')"
          numberValue="03"
          class="mt-24"
        >
          <safe-html :html="$t('studio.business_bank_setting.guide2_10')" />
        </right-wing-item>
        <right-wing-item
          :rightWingTitle="$t('studio.business_bank_setting.guide2_11')"
          numberValue="04"
          class="mt-24"
        >
          <safe-html :html="$t('studio.business_bank_setting.guide2_12')" />
        </right-wing-item>
        <p class="mt-8 text-sm text-on-surface-elevation-4 leading-md font-regular">
          {{ $t('studio.business_bank_setting.guide2_17') }}
        </p>
        <div class="mt-4">
          <img :src="sampleImagesSrc" alt="" class="w-full block" />
        </div>
        <right-wing-item
          :rightWingTitle="$t('studio.business_bank_setting.guide2_13')"
          numberValue="05"
          class="mt-24"
        >
          <safe-html :html="$t('studio.business_bank_setting.guide2_14')" />
        </right-wing-item>
        <right-wing-item
          :rightWingTitle="$t('studio.business_bank_setting.guide2_15')"
          numberValue="06"
          class="mt-24"
        >
          <safe-html :html="$t('studio.business_bank_setting.guide2_16')" />
        </right-wing-item>
      </template>
    </template>
  </right-wing>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import * as SampleImages from '@/assets/images/business-bank/11-1-7-sample';
import RightWing from '@/components/app/right-wing/index.vue';
import RightWingItem from '@/components/app/right-wing/item.vue';
import safeHtml from '@/components/common/safe-html.vue';
import { DEFAULT_COUNTRY } from '@/constants/cookie.const';

defineProps<{
  haveLicense: boolean;
  nation: string;
}>();

const { locale } = useI18n();
const sampleImages = ref<Record<string, string>>(SampleImages);
const sampleImagesSrc = computed(
  () => sampleImages.value[`Sample_${locale.value.replace('-', '_')}`]
);

const showGuides = [
  {
    title: 'studio.business_bank_setting.guide2_1',
    content: 'studio.business_bank_setting.guide2_2'
  },
  {
    title: 'studio.business_bank_setting.guide2_3',
    content: 'studio.business_bank_setting.guide2_4'
  }
];
</script>
