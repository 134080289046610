<template>
  <div class="flex gap-24 mt-40">
    <div class="flex-1">
      <st-box v-if="shouldRender">
        <license-check :disabled="disabled" />
      </st-box>

      <component
        :is="getBusinessInfoComponent"
        :modeDisabled="disabled"
        :isEdit="isEdit"
        :countryOptions="countryOptions"
        :defaultCountryOption="defaultCountryOption"
        @onValidityChange="handleValidityChange"
        @vue:mounted="setComponentRendered"
      />

      <div class="mt-40 flex justify-center gap-16">
        <s-button
          variant="primary"
          size="lg"
          class="!min-w-160"
          :disabled="haveLicense === '' || disabled || !isFormValid"
          @click="onSubmit"
        >
          {{ $t('studio.business_bank_setting.info_review_req_btn') }}
        </s-button>
      </div>
    </div>

    <!--Right wing-->
    <individual-guide :haveLicense="haveLicense === Confirmation.YES" :nation="nation" />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useFieldValue } from 'vee-validate';
import { computed, defineAsyncComponent, ref, watch } from 'vue';

import LicenseCheck from '@/components/business-bank-info/business-info/license-check.vue';
import IndividualGuide from '@/components/business-bank-info/common/guide/individual-guide.vue';
import StBox from '@/components/common/st-box.vue';
import { Confirmation } from '@/enums/common.enum';
import { useAppStore } from '@/stores/app.store';
import { useBusinessBankStore } from '@/stores/business-bank.store';
import { useCountryStore } from '@/stores/country.store';

defineProps<{
  disabled: boolean;
  isEdit: boolean;
}>();

const emits = defineEmits<{
  onSubmit: [v: void];
}>();

const BUSINESS_INFO_COMPONENTS = {
  LICENSE: 'LICENSE',
  NO_LICENSE: 'NO_LICENSE'
};

const appStore = useAppStore();
const businessBankStore = useBusinessBankStore();
const countryStore = useCountryStore();

const { countryOptions } = storeToRefs(countryStore);
const { defaultCountryOption } = storeToRefs(businessBankStore);

const { startLoading, endLoading } = appStore;

const haveLicense = useFieldValue<string>('individual.license');
const nation = useFieldValue<string>('individual.businessInfo.nation');

const isFormValid = ref<boolean>(true);

const loadingId = new Date().getTime();

const isComponentMounted = ref({
  noLicense: false,
  license: false
});

const onSubmit = () => {
  emits('onSubmit');
};

const isLicenseComponentNotRendered = computed(() => {
  return haveLicense.value === Confirmation.YES && !isComponentMounted.value.license;
});

const isNoLicenseComponentNotRendered = computed(() => {
  return haveLicense.value === Confirmation.NO && !isComponentMounted.value.noLicense;
});

const shouldRender = computed(() => {
  if (
    haveLicense.value === '' ||
    isNoLicenseComponentNotRendered.value ||
    isLicenseComponentNotRendered.value
  ) {
    return true;
  }

  return false;
});

const setComponentRendered = () => {
  if (haveLicense.value === Confirmation.YES) {
    isComponentMounted.value.license = true;
  } else {
    isComponentMounted.value.noLicense = true;
  }
};

const handleValidityChange = (isValid: boolean) => {
  isFormValid.value = isValid;
};

const getBusinessInfoComponent = computed(() => {
  switch (haveLicense.value) {
    case Confirmation.YES:
      return businessInfoComponents[BUSINESS_INFO_COMPONENTS.LICENSE];
    case Confirmation.NO:
      return businessInfoComponents[BUSINESS_INFO_COMPONENTS.NO_LICENSE];
    default:
      return '';
  }
});

const businessInfoComponents = {
  [BUSINESS_INFO_COMPONENTS.NO_LICENSE]: defineAsyncComponent(
    () => import('@/components/business-bank-info/business-info/no-license/index.vue')
  ),
  [BUSINESS_INFO_COMPONENTS.LICENSE]: defineAsyncComponent(
    () => import('@/components/business-bank-info/business-info/have-license/index.vue')
  )
};

watch(
  () => [isLicenseComponentNotRendered.value, isNoLicenseComponentNotRendered.value],
  (value: boolean[]) => {
    if (value[0] || value[1]) {
      startLoading(loadingId);
    } else {
      endLoading(loadingId);
    }
  }
);
</script>
